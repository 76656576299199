import React, { useState } from 'react';

const SmartFeedNavigation = ({ active, onNavChange }) => {
  return (
    <div className="vf-navigation btn-group flex-wrap flex-md-nowrap w-100">
      <button
        className={`btn border ${
          active === 'Careers' ? 'btn--primary' : 'btn-light'
        } w-100`}
        onClick={() => {
          onNavChange('Careers');
        }}
      >
        Current Vacancies
      </button>
      <button
        className={`btn border ${
          active === 'Internal' ? 'btn--primary' : 'btn-light'
        } w-100`}
        onClick={() => {
          onNavChange('Internal');
        }}
      >
        Internal Vacancies
      </button>
      <button
        className={`btn border ${
          active === 'Register' ? 'btn--primary' : 'btn-light'
        } w-100`}
        onClick={() => {
          onNavChange('Register');
        }}
      >
        Register Your Interest
      </button>
      <button
        className={`btn border ${
          active === 'Alerts' ? 'btn--primary' : 'btn-light'
        } w-100`}
        onClick={() => {
          onNavChange('Alerts');
        }}
      >
        Sign up to Job Alerts
      </button>
    </div>
  );
};

export default SmartFeedNavigation;
